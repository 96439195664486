import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function DynamicWeb() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const DW = {
    logo: "services-img/DWLogo.png",
    heading: "Dynamic Web",
    image: "services-img/dynamicweb9.Jpg",
    intro: [
      "Dynamicweb offers a cloud based eCommerce suite.It enable customers to deliver better digital customer experiences and to scale ecommerce success through our Content Management, Digital Marketing, Ecommerce, and Product Information Management solutions.",
      "Dynamicweb is delivered as an All-In-One Business Platform for large and medium enterprises with all available functionality and the ability to scale with enterprise service levels.",
    ],
    why: [
      {
        heading: "All-In-One Platform​",
        content: "Combining Content, PIM, Ecommerce and Marketing.",
        logo: "dw-icons/database.Png",
      },
      {
        heading: "Built to digitalize",
        content: "Retail, distribution and manufacturing businesses.",
        logo: "dw-icons/marketing.Png",
      },
      {
        heading: "Integration framework​​",
        content: "To Dynamics AX, NAV, D365, Business Central and others.",
        logo: "dw-icons/solutions.Png",
      },
      {
        heading: "Rapid implementation​​",
        content:
          "Successful projects delivered on time via proven methodology.",
        logo: "dw-icons/omnichannel2.Png",
      },
      {
        heading: "Empowering end-users​",
        content: "Acomplish more, in less time - without coding.",
        logo: "dw-icons/process.Png",
      },
      {
        heading: "Global deployment​",
        content: "Multi-site, multi-language, multi-currency, multi-VAT.",
        logo: "dw-icons/world2.Png",
      },
    ],
  };
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-gray-800 to-blue-900 justify-between items-end">
        <span className="w-[45%] md:w-1/6 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={DW.logo} alt="Dynamic Web Logo" />
        </span>
      </div>
      <div className="w-full md:my-12 my-8 flex flex-col-reverse p-4 md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {DW.heading} ?
          </h1>
          {DW.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
          <div className="w-full md:flex-row flex-col mt-2 md:mt-12 md:w-[95%] flex justify-between rounded-lg bg-blue-50 items-center p-2">
            <span className="md:text-xl font-semibold text-blue-900 py-2">
              Do you know we are Dynamic Web Partner ?
            </span>
            <a
              className="p-2 font-semibold text-sm md:w-[11%] rounded-lg flex justify-center text bg-blue-900 hover:bg-blue-800 transition duration-300 text-white"
              rel="noreferrer"
              href="https://www.dynamicweb.com/partners/kppl-dynamics-pvt-ltd"
              target="_blank"
            >
              Click Here
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={DW.image} alt="hands" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-around p-6 md:p-10 text-white bg-blue-900">
        <h1 className="w-full text-center md:text-3xl text-2xl font-semibold">
          Why Choose Dynamic Web ?
        </h1>
        <div className="w-full mt-4 p-2 md:p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        {DW.why.map((item, key) => (
            <div
              className="w-full flex bg-white text-black rounded-md shadow-xl"
              key={key}
            >
              <div className="w-1/4 p-2 flex justify-center items-center">
                <img className="p-2" src={item.logo} alt="logo" />
              </div>
              <div className="w-3/4">
              <h1 className="p-2 border-b-2 border-blue-900 text-blue-900 text-xl font-semibold">
                {item.heading}
              </h1>
              <p className="p-2 text-md font-semibold">
                {item.content}
              </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mt-6 md:mt-10 p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl text-center">
          Looking for Dynamic Web Partner ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2  border-black rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Contact Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
