import React from "react";
import { FaInstagram, FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";

export default function SocialLinks() {
  return (
    <div className="flex items-center">
      <ul className="flex">
        <li className="text-xl ml-1 mr-1">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.facebook.com/KPPLDynamics2020"
          >
            <FaFacebook />
          </a>
        </li>
        <li className="text-xl ml-1 mr-1">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.instagram.com/kppldynamics/"
          >
            <FaInstagram />
          </a>
        </li>
        <li className="text-xl ml-1 mr-1">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/KpplLtd"
          >
            <FaTwitter />
          </a>
        </li>
        <li className="text-xl ml-1 mr-1">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://www.linkedin.com/company/kppl-dynamics-pvt-ltd/"
          >
            <FaLinkedin />
          </a>
        </li>
      </ul>
    </div>
  );
}
