import React from "react";
import KPPL from "../assets/KPPL.png";
import SocialLinks from "./SocialLinks";
//import { IoSend } from "react-icons/io5";
import { Link } from "react-router-dom";
export default function Footer() {
  const date = new Date();
  const year = date.getFullYear();
  return (
    <footer className="relative w-full flex flex-col items-center p-4 md:p-8 bg-gradient-to-r from-gray-800 to-gray-900 text-white">
      <div className="xl:max-w-screen-2xl md:w-full flex flex-col md:flex-row md:px-12">
        <div className="w-full flex flex-col">
          <Link to="/">
            <img src={KPPL} alt="KPPL Logo" />
          </Link>

          <p className="p-2 text-md">
            We Believe in Knowledge Progress Process & Learning.
          </p>
          <div className="mt-2">
            <SocialLinks />
          </div>
        </div>
        <div className="w-full flex flex-col justify-between md:justify-start">
          <h6 className="p-2 border-b-2 border-solid border-white uppercase font-semibold">
            Company
          </h6>
          <ul className="p-2">
            <Link to="/about-us">
              <li>About us</li>
            </Link>
            <Link to="/contact">
              <li>Contact us</li>
            </Link>
            <Link to="/industries">
              <li>Industries</li>
            </Link>
            <Link to="/">
              <li>Blog</li>
            </Link>
          </ul>
        </div>
        <div className="w-full flex flex-col ">
          <h6 className="md:ml-2 p-2 border-b-2 border-solid border-white uppercase font-semibold">
            Products
          </h6>
          <div className="flex p-2 justify-between md:justify-start">
            <div className="flex flex-col md:ml-2 md:mr-2 ">
              <ul>
                <Link to={{ pathname: "deals-in", hash: "navision" }}>
                  <li>Microsoft Dynamics NAV</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "business-central" }}>
                  <li>D365 Business Central</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "ls-retail" }}>
                  <li>LS Retail</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "jet-reports" }}>
                  <li>Jet Reports</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "ls-central" }}>
                  <li>LS Central</li>
                </Link>
              </ul>
            </div>
            <div className="flex flex-col md:ml-2 md:mr-2">
              <ul>
                <Link
                  to={{ pathname: "deals-in", hash: "finance-and-operation" }}
                >
                  <li>D365 F&O</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "power-bi" }}>
                  <li>Power BI</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "dynamic-web " }}>
                  <li>Dynamics Web</li>
                </Link>
                <Link to={{ pathname: "deals-in", hash: "oracle" }}>
                  <li>Oracle</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="flex flex-col justify-between md:justify-start ">
          <h6 className="p-2 border-b-2 border-solid border-white uppercase font-semibold">
            Subscribe
          </h6>
          <p className="p-2">
            Subscribe to our newsletter and get some freebies stuff every week.
          </p>
          <div className="overflow-hidden rounded-md md:w-3/4 sm:w-1/2 ml-2 bg-white flex justify-between ">
            <div className="flex justify-center items-center p-2">
              <input
                type="email"
                placeholder="Your Email Address"
                className="text-black outline-none"
              />
            </div>
            <div className="flex justify-center items-center p-2">
              <button className="text-black">
                <IoSend />
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div className="flex justify-center p-2">
        <p>
          © {year} <a href="https://dcrazed.com/">KPPL Dynamics</a>. All rights
          reserved
        </p>
      </div>
    </footer>
  );
}
