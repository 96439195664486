import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
export default function Sidebar({ setOpenSidebar, openSidebar }) {
  const [dealsInMenu, setDealsInMenu] = useState(false);
  const [mdMenu, setMdMenu] = useState(false);
  const [retailMenu, setRetailMenu] = useState(false);

  return (
    <div
      className={`transition duration-500 ease-in-out w-full z-50 min-h-screen absolute top-0 left-0 bg-black bg-opacity-70  text-black ${
        openSidebar ? `` : `-tranxlate-x-full`
      } `}
    >
      <span
        className="absolute right-0 p-1 text-white"
        onClick={() => setOpenSidebar(false)}
      >
        <IoClose />
      </span>
      <div className="w-3/4 min-h-screen bg-white text-lg font-semibold">
        <ul className="p-2">
          <Link to="/" onClick={() => setOpenSidebar(false)}>
            <li className="p-2">Home</li>
          </Link>
          <li
            className="w-full p-2 flex flex-col items-center"
            onClick={() => setDealsInMenu(true)}
          >
            <span className="w-full flex items-center">
              <span>Deals In</span>
              <span>
                <BiChevronDown />
              </span>
            </span>
            <ul className={`w-full ${dealsInMenu ? "" : "hidden"}`}>
              <li>
                <span
                  className="w-full p-2 flex items-center"
                  onClick={() => setMdMenu(true)}
                >
                  <span>Microsoft Dynamics</span>
                  <span>
                    <BiChevronDown />
                  </span>
                </span>
                <ul
                  className={`w-full ${dealsInMenu && mdMenu ? "" : "hidden"}`}
                >
                  <Link
                    to={{ pathname: "deals-in", hash: "navision" }}
                    onClick={() => setOpenSidebar(false)}
                  >
                    <li className="p-2 pl-4">Dynamics Navision</li>
                  </Link>
                  <Link
                    to={{ pathname: "deals-in", hash: "business-central" }}
                    onClick={() => setOpenSidebar(false)}
                  >
                    <li className="p-2 pl-4">Business Central</li>
                  </Link>
                  <Link
                    to={{ pathname: "deals-in", hash: "finance-and-operation" }}
                    onClick={() => setOpenSidebar(false)}
                  >
                    <li className="p-2 pl-4">Finance & Operation</li>
                  </Link>
                </ul>
              </li>
              <li className="w-full">
                <span
                  className="w-full p-2 flex items-center"
                  onClick={() => setRetailMenu(true)}
                >
                  <span>Retail</span>
                  <span>
                    <BiChevronDown />
                  </span>
                </span>
                <ul className={`w-full ${retailMenu ? "" : "hidden"}`}>
                  <Link to={{ pathname: "deals-in", hash: "ls-retail" }}>
                    <li
                      className="p-2 pl-4"
                      onClick={() => setOpenSidebar(false)}
                    >
                      LS Retail
                    </li>
                  </Link>
                  <Link
                    to={{ pathname: "deals-in", hash: "ls-central" }}
                    onClick={() => setOpenSidebar(false)}
                  >
                    <li className="p-2 pl-4">LS Central</li>
                  </Link>
                </ul>
              </li>
              <Link
                to={{ pathname: "deals-in", hash: "power-bi" }}
                onClick={() => setOpenSidebar(false)}
              >
                <li className="p-2">Power BI</li>
              </Link>
              <Link
                to={{ pathname: "deals-in", hash: "jet-reports" }}
                onClick={() => setOpenSidebar(false)}
              >
                <li className="p-2">Jet Reports</li>
              </Link>
              <Link
                to={{ pathname: "deals-in", hash: "dynamic-web" }}
                onClick={() => setOpenSidebar(false)}
              >
                <li className="p-2">Dynamic Web</li>
              </Link>
              <Link
                to={{ pathname: "deals-in", hash: "oracle" }}
                onClick={() => setOpenSidebar(false)}
              >
                <li className="p-2">Oracle</li>
              </Link>
            </ul>
          </li>
          <Link to="/about-us" onClick={() => setOpenSidebar(false)}>
            <li className="p-2">About Us</li>
          </Link>
          <Link to="/industries" onClick={() => setOpenSidebar(false)}>
            <li className="p-2">Industries</li>
          </Link>
          <Link to="/contact" onClick={() => setOpenSidebar(false)}>
            <li className="p-2">Contact</li>
          </Link>
          <Link to="/" onClick={() => setOpenSidebar(false)}>
            <li className="p-2">Blog</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}
