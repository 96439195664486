import React, { useEffect } from "react";
import { TiChevronRight } from "react-icons/ti";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function LSCentral() {
  const ls = {
    logo: "services-img/LSc.png",
    heading: "LS Central",
    image: "services-img/central.png",
    intro: [
      " LS Central, the flagship product, is a fully integrated retail & hospitality management solution that delivers the breadth and depth of functionality demanded by today's organizations, without the need to build, manage and maintain multiple applications and costly interfaces.",
      "It is a complete unified commerce platform that is built on Microsoft Dynamics 365 Business Central, powered by Azure.",
    ],
    capabilities: [
      "In-Store Management",
      "Inventory Optimizer",
      "Replenishment",
      "Mobile POS",
      "BI Solution",
    ],

    benifits: [
      {
        img: "",
        heading: "Delightful Customer Experience",
        content:
          "Customer-centric retailing has taken a big leap with this state-of-the-art product. Users can now be rest assured of a consistent look and feel across all channels. What's more, there is a mobile application, which provides personalized product info, promotions & incentives based on customer loyalty.",
      },
      {
        img: "",
        heading: "Easy Financial Management",
        content:
          "With LS Central, Companies benefit with a centralized control over price management, inventory turnovers, order backlogs, and offer & discount timings. The unified platform solves the all so cumbersome process of managing inconsistent financial data and easy reports provide real time inputs for better decision making.",
      },
      {
        img: "",
        heading: "Reduce Shrinkage",
        content:
          "Inventory shrinkage and staff theft are some of the very real threats that are faced by rapidly growing organizations. In the retail & hospitality industries, these scenarios can easily be avoided by in-built, lie-detector type intelligence that scans transactions across stores for highlighting anomalies and irregular situations for which corrective actions can then be easily taken.",
      },
      {
        img: "",
        heading: "Superior Inventory Management",
        content:
          "Organizations can take advantage of features such as trend forecasting, product mix optimization per store, understanding under-and over-stocked items, safety stock measures, and multiple automatic & manual replenishment mechanisms. LS Central provides real time inventory counts across the entire company, including transfers and layaways.",
      },
      {
        img: "",
        heading: "Robust Resiliency",
        content:
          "Being real time and connected is one of the most important features of a POS-back-office-store infrastructure for today's profitable organizations. The LS Central product suite works in the offline as well as the online mode, ensuring that the cash register can keep ringing in sales even during unprecedented network outages.",
      },
      {
        img: "",
        heading: "Reduced Risk",
        content:
          "Employees get access only to functions they need based on their user roles and permissions. Risk of IT system maintenance is also reduced since with the unified platform, there are now fewer system integrations to handle.",
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-gray-800 to-blue-900 justify-between items-end">
        <span className="w-full md:w-1/2 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={ls.logo} alt="LS Logo" />
        </span>
      </div>
      <div className="w-full my-10 md:my-16 flex flex-col-reverse p-4 md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {ls.heading} ?
          </h1>
          {ls.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
          <h1 className="mt-2 text-2xl text-blue-900 font-semibold">
            Core Capabilities :
          </h1>
          {ls.capabilities.map((item, key) => (
            <p
              key={key}
              className="w-full md:w-1/2 flex items-center text-md font-semibold mt-2 md:mt-1 md:pr-4"
            >
              <TiChevronRight />
              {item}
            </p>
          ))}
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={ls.image} alt="hands" />
        </div>
      </div>
      <div className="w-full text-2xl text-white bg-blue-900 flex justify-center md:text-3xl p-4 md:p-6 md:pr-10 md:pl-10 font-semibold">
        <h1>Benifits Of LS Central</h1>
      </div>
      <div className="w-full my-10 md:my-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8 xl:gap-10 p-4 md:p-6 lg:p-8 xl:p-10">
        {ls.benifits.map((item, key) => (
          <div className="w-full shadow-lg rounded-lg h-80" key={key}>
            <div className="w-full h-[25%] sm:h-[30%] flex relative overflow-hidden rounded-lg mt-4 md:mt-0">
              <div className="w-[25%] sm:w-[30%] bg-blue-900 -translate-x-3 sm:-translate-x-8 lg:-translate-x-6 xl:-translate-x-10 -translate-y-2 rounded-full flex justify-center md:pl-4 items-center text-xl sm:text-4xl text-white shadow-xl">
                <img className="w-[35%]" src={item.img} alt="" />
              </div>
              <div className="h-full flex items-center md:text-xl font-semibold text-blue-900 text-md sm:text-lg">
                {item.heading}
              </div>
            </div>
            <div className="w-full h-[60%] flex justify-center items-center p-4 font-semibold text-sm sm:text-base">
              {item.content}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full bg-blue-900 text-white p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl">
          Want to chat about LS Central ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2 rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Connect Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
