import React, { useEffect } from "react";
import { TiChevronRight } from "react-icons/ti";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function FandO() {
  const fo = {
    logo: "services-img/F&OLogo1.png",
    heading: "Finance & Operations",
    image: "services-img/FandO.png",
    intro: [
      "Dynamics 365 Finance & Operations is a cloud-based Enterprise Resource Planning (ERP) system well-suited for large or diversified companies. It offers you the ability to keep track of all your finances and inventory.",
      "Even though most customers, commentators, and consultants still call it Finance & Operations, Microsoft now technically licenses it as 2 different apps:",
    ],
    apps: [
      {
        heading: "Dynamics 365 Finance",
        content:
          "Includes budgeting, project management, financials, and accounting for large, international companies.",
      },
      {
        heading: "Dynamics 365 Supply Chain Management",
        content:
          "Includes engineering, manufacturing, warehousing, and distribution.",
      },
    ],
    features: {
      heading: " Finance & Operations Features",
      content:
        "Dynamics 365 Finance & Operations is often the right choice for large, complex, or diversified companies that want to manage their finances, inventory, and more in the cloud.",
      parts: [
        {
          heading: "Integrated, Flexible, and Scalable",
          content:
            "Start with Dynamics 365 Finance & Operations, then add more Microsoft business apps as you grow:",
          points: [
            "Dynamics 365 for Sales",
            "Dynamics 365 for Customer Service",
            "Dynamics 365 for Field Service",
            "Dynamics 365 for Project Service Automation",
            "Dynamics 365 Human Resources",
            "Power BI",
          ],
        },
        {
          heading: "Automation, Efficiency, and Control",
          content:
            "If you want a financial system that is familiar and integrates with the rest of your Microsoft technology, look no further.",
          points: [
            "Manage your finances: quote, invoice, make payments, and collect.",
            "Easily see all the data you need to prepare financial statements and analyze company performance.",
            "Manage your inventory, supply chain, projects, manufacturing, and more.",
            "Automate tasks to increase productivity and unleash creativity across your enterprise.",
            "Handle tax and compliance with confidence. Ideal for multi-currency, multi-country enterprises.",
          ],
        },
        {
          heading: "A Cloud-Based SaaS",
          content:
            "Dynamics 365 Finance & Operations is a cloud-based, software-as-a-service (SaaS) ERP system. This cloud focus gives you",
          points: [
            "Secure and efficient capabilities for remote work",
            "Semi-annual and quarterly updates",
            "More accessible mobile and web apps",
            "Monthly, per-user billing cycles that let you scale up and down easily",
            "Better integration with other Microsoft Azure cloud products",
            "Your choice of preferred data center region for hosting",
            "World-class hosting security and stability",
            "A larger share of Microsoft’s future R&D",
          ],
        },
      ],
    },
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div
        className="w-full h-[260px] xl:h-[400px] overflow-hidden flex justify-between items-end"
        style={{ backgroundColor: "#002050" }}
      >
        <span className="w-full md:w-1/2 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={fo.logo} alt="fando Logo" />
        </span>
      </div>
      <div className="w-full my-6 md:my-10 flex flex-col-reverse p-4 md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is Finance and Operation ?
          </h1>
          {fo.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
          {fo.apps.map((item, key) => (
            <p
              key={key}
              className="w-full md:w-1/2 text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              <span className="text-blue-900 text-lg flex items-center">
                <TiChevronRight /> {item.heading} :
              </span>
              {item.content}
            </p>
          ))}
        </div>
        <div className="w-full  my-6 md:my-10 md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={fo.image} alt="hands" />
        </div>
      </div>
      <div className="w-full md:min-h-screen bg-blue-900 text-white">
        <div className="w-full p-4 md:p-10 flex flex-col justify-center items-center">
          <h1 className="text-2xl md:text-3xl p-2 border-b-2 font-semibold">
            {fo.features.heading}
          </h1>
          <p className="w-full text-md text-center font-semibold p-2">
            {fo.features.content}
          </p>
        </div>
        <div className="w-full flex md:flex-row flex-col pr-4 pl-4 md:pr-10 md:pl-10">
          {fo.features.parts.map((item, index) => (
            <div className="md:w-1/3 p-2" key={index}>
              <h1 className="text-md md:text-lg font-semibold pr-2 pl-2 border-b-2">
                {item.heading}
              </h1>
              <p className="w-full text-md font-semibold p-2">{item.content}</p>
              {item.points.map((point, key) => (
                <p className=" pt-2 flex items-start" key={key}>
                  <span className="flex justify-center items-center">
                    <TiChevronRight />
                  </span>
                  {point}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full mt-6 md:mt-10 p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl text-center">
          Looking for Dynamics 365 Partner ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2  border-black rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Contact Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
