import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./container/Home";
import Footer from "./components/Footer";
import Contact from "./container/Contact";
import ChatBox from "./components/ChatBox";
import About from "./container/About";
import DealsIn from "./components/DealsIn";
import Industries from "./container/Industries";
import BusinessCentral from "./components/BusinessCentral";
import Navision from "./components/Navision";
import FandO from "./components/FandO";
import LS from "./components/LSCentral";
import LSR from "./components/LSRetail";
import BI from "./components/PowerBi";
import JetReports from "./components/JetReports";
import Oracle from "./components/Oracle";
import DynamicWeb from "./components/DynamicWeb";
export default function App() {
  return (
    <div className="w-full">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/industries" element={<Industries />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/deals-in" element={<DealsIn />} />
        <Route path="/d365-business-central" element={<BusinessCentral />} />
        <Route path="/dynamics-navision" element={<Navision />} />
        <Route path="/finance-and-operations" element={<FandO />} />
        <Route path="/ls-central" element={<LS />} />
        <Route path="/ls-retail" element={<LSR />} />
        <Route path="/power-bi" element={<BI />} />
        <Route path="/jet-reports" element={<JetReports />} />
        <Route path="/oracle" element={<Oracle />} />
        <Route path="/dynamic-web" element={<DynamicWeb />} />
      </Routes>
      <ChatBox />
      <Footer />
    </div>
  );
}
