import React from "react";
import { IoIosTimer } from "react-icons/io";

export default function AboutUs() {
  return (
    <div className="w-full my-10 md:my-16 flex justify-center">
      <div className="relative p-2 max-w-screen-2xl z-20 flex overflow-hidden justify-center flex-col md:flex-row-reverse md:mt-4">
        {/* <div className="flex  flex-col w-full md:w-2/5 p-4 bg-blue-900 text-white rounded-xl shadow-lg">
          <h2 className="text-center font-bold text-2xl ">About Us</h2>
          <p className="p-2 md:pt-4 md:pb-4 font-semibold text-center">
            KPPL is a preferred business application & IT solution partner for
            global businesses. We focus on delivering value to the organization
            through business application, cloud & analytics. We are working
            extensively in implementing Microsoft Dynamics business
            applications, LS Retail, Power BI, Jet Reports & E-Commerce
            Solutions.
          </p>
        </div> */}
        <div className="md:w-[80%] mt-2 md:mt-0 rounded-xl  shadow-md shadow-blue-900 border border-blue-900 p-4 md:p-2 flex flex-col md:grid md:grid-cols-3 md:m-4  md:gap-20 text-center">
          <div className="flex z-20 flex-col justify-center p-2">
            <h6 className="flex justify-center items-center font-semibold text-xl">
              <span className="text-white h-6 w-6 flex justify-center items-center rounded-full bg-blue-900">
                <IoIosTimer />
              </span>
              <span className="ml-2 text-blue-900">History</span>
            </h6>
            <p className="mt-2 p-2 text-gray text-sm font-semibold">
              We are fastest growing partner in the field of Dynamics, having
              50+ years of in person experiance .
            </p>
          </div>
          <div className="flex z-20 flex-col justify-center p-2">
            <h6 className="flex justify-center items-center font-semibold text-xl">
              <span className="text-white h-6 w-6 flex justify-center items-center rounded-full bg-blue-900">
                <IoIosTimer />
              </span>
              <span className="ml-2 text-blue-900">Expertise</span>
            </h6>
            <p className="mt-2 p-2 text-gray text-sm font-semibold">
              Our Core expertise in Retail Domain including Grocery, Fashion,
              Hospitality (QSR & Fine dining).
            </p>
          </div>
          <div className="flex z-20 flex-col justify-center p-2">
            <h6 className="flex justify-center items-center font-semibold text-xl">
              <span className="text-white h-6 w-6 flex justify-center items-center rounded-full bg-blue-900">
                <IoIosTimer />
              </span>
              <span className="ml-2 text-blue-900">Future Plans</span>
            </h6>
            <p className="mt-2 p-2 text-gray text-sm font-semibold">
              Looking cautiously round and to help enterprises achieve business
              growth by providing latest & best in class IT solutions and
              services.
            </p>
          </div>
        </div>
        {/* Why us */}
        {/* <div className=" relative w-full h-24 font-semibold text-2xl flex justify-center items-center bg-blue-900 text-white">
        <span className="z-10 absolute translate-y-4 w-20 h-20 bg-blue-900 rounded rotate-45"></span>
        <span className="z-20">Why Choose Us</span>
      </div>
      <div className="flex flex-col mt-10 w-full items-center">
        <ul className="flex uppercase font-semibold ">
          <li className="p-2">Knowledge</li>
          <li className="p-2">Progress</li>
          <li className="p-2">Process</li>
          <li className="p-2">Learning</li>
        </ul>
        <div className="w-full flex p-4 justify-center">
          <img src={KNOWLEDGE} alt="" />
        </div>
        <div className="w-full flex items-center h-24 bg-blue-900 text-white">
          <ul className="flex w-full justify-around uppercase font-semibold ">
            <li className="flex flex-col p-2 text-center">
              <span className="flex justify-center items-center">
                <FaUsers className="text-2xl" />
                <p className="ml-2">16</p>
              </span>
              <span>Clients</span>
            </li>
            <li className="flex flex-col p-2 text-center">
              <span className="flex justify-center items-center">
                <TiTick className="text-2xl" />
                <p className="ml-2">43</p>
              </span>
              <span>Finished Projects</span>
            </li>
            <li className="flex flex-col p-2 text-center">
              <span className="flex justify-center items-center">
                <FaIndustry className="text-2xl" />
                <p className="ml-2">48</p>
              </span>
              <span>Types Of Industries</span>
            </li>
            <li className="flex flex-col p-2 text-center">
              <span className="flex justify-center items-center">
                <FaHeadphones className="text-2xl" />
                <p className="ml-2">4</p>
              </span>
              <span>Offshore Presence</span>
            </li>
          </ul>
        </div>
      </div> */}
      </div>
    </div>
  );
}
