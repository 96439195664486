import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Solutions from "../components/Solutions";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import { BsArrowRight } from "react-icons/bs";
import ChecksBanner from "../assets/checksBanner.jpg";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-auto">
      <div className="w-full">
        <div className="w-full">
          <div className="w-full top-[45px] sm:top-0 flex justify-center items-center overflow-hidden md:h-[650px] relative bg-cover bg-center">
            <img
              className="w-full bg-center "
              src={ChecksBanner}
              alt="bg-img"
            />
            <div className="md:hidden max-w-screen-2xl h-full absolute top-[2%] flex justify-center sm:justify-start  items-center bg-gray-800 bg-opacity-50 ">
              <p className="w-3/4 relative sm:w-1/2 sm:left-10 text-sm text-white font-semibold">
                BELIEVES IN KNOWLEDGE PROGRESS PROCESS & LEARNING
              </p>
            </div>
            <Link
              to="/contact"
              className="absolute top-[50%] left-9 md:hidden"
            >
              <button className="p-3 text-xs flex justify-center rounded-md hover:bg-blue-800 md:w-1/4 font-semibold bg-blue-900 mt-8 text-white hover:ease-in-out duration-500">
                Connect Us
                <span className="ml-2 self-center">
                  <BsArrowRight />
                </span>
              </button>
            </Link>
          </div>
          <div className="hidden md:block md:w-1/2 bg-opacity-60 absolute bg-gray-800 rounded-md shadow-md lg:top-[12%] md:top-[10%] xl:top-[23%] 2xl:top-[20%] text-white md:left-40">
            <div className="flex flex-col lg:m-2 p-6 lg:p-8 ">
              <p className="text-xl lg:text-3xl uppercase font-bold">
                CUSTOMER FIRST APPROACH !
              </p>
              <p className="text-lg lg:text-xl mt-2 uppercase">
                BELIEVES IN KNOWLEDGE PROGRESS PROCESS & LEARNING
              </p>
              <p className="text-base md:text-xl mt-10 md:mt-16 font-semibold">
                We Understand Your Business through Our Vast Industry
                Experiance. Lets grow together.
              </p>
              <Link to="/contact">
                <button className="p-4 flex justify-center rounded-md hover:bg-blue-800 lg:w-1/4 font-semibold bg-blue-900 mt-8 text-white hover:ease-in-out duration-500">
                  Connect Us
                  <span className="ml-2 self-center">
                    <BsArrowRight />
                  </span>
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AboutUs />
      <Solutions />
      <Services />
    </div>
  );
}
