import React from "react";
import SocialLinks from "./SocialLinks";
import Navbar from "./Navbar";

export default function Header() {
  return (
    <header className="w-full shadow-md z-50 fixed">
      <div className="flex justify-around  bg-blue-900 text-white p-2 relative ">
        <div className="max-w-screen-2xl">
          <ul>
            <li>Phone: +91 98730 48364</li>
          </ul>
        </div>
        <SocialLinks />
      </div>
      <Navbar/>
    </header>
  );
}
 