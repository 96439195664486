import React, { useState, useEffect } from "react";
import { BiChevronDown } from "react-icons/bi";
import KPPL from "../assets/kppl-logo.png";
import { Link } from "react-router-dom";
import { CgMenu } from "react-icons/cg";
import Sidebar from "./Sidebar";

export default function Navbar() {
  const timeDuration = 100;
  const [dealsInMenu, setDealsInMenu] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [mdMenu, setMdMenu] = useState(false);
  const [retailMenu, setRetailMenu] = useState(false);
  useEffect(() => {
    if (toggleSidebar) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [toggleSidebar]);

  const toggleDealsInMenu = (state, action) => {
    let timeout;
    if (
      (state && action === "onMouseEnter") ||
      (!state && action === "onMouseLeave")
    ) {
      clearTimeout(timeout);
      timeout = setTimeout(() => setDealsInMenu(state), timeDuration);
    }
  };
  return (
    <nav className="w-full bg-white">
      <section className="flex z-50 justify-around p-2">
        <div className="flex h-full justify-center hover:cursor-pointer">
          <Link className="flex" to="/">
            <img
              className="pl-2 md:ml-0 w-[80%] md:w-full"
              src={KPPL}
              alt="KPPL logo"
            />
          </Link>
        </div>
        <div
          className="flex justify-between items-center"
          onMouseEnter={() => {
            if (dealsInMenu) {
              setDealsInMenu(false);
            }
          }}
        >
          <div className="hidden md:flex md:flex-row md:items-center self-center">
            <ul className="flex  items-center">
              <Link to="/">
                <li className="hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                  Home
                </li>
              </Link>
              <span
                
                onMouseEnter={() => toggleDealsInMenu(true, "onMouseEnter")}
                onMouseLeave={() => {
                  toggleDealsInMenu(false, "onMouseLeave");
                  setRetailMenu(false);
                  setMdMenu(false);
                }}
              >
                <li className="relative flex items-center hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                  Deals In <BiChevronDown />
                  <ul
                    onMouseEnter={() => setDealsInMenu(true)}
                    onMouseLeave={() =>
                      dealsInMenu ? setDealsInMenu(false) : dealsInMenu
                    }
                    className={`${
                      dealsInMenu ? "" : "hidden"
                    } absolute w-[250%] top-[22px] pt-6 overflow-hidden bg-white transition hover:ease-in duration-500`}
                  >
                    <Link to="/deals-in" onClick={() => setDealsInMenu(false)}>
                      <li>
                        <span
                          className="w-full p-2 text-sm flex items-center hover:bg-blue-900  hover:text-white"
                          onMouseEnter={() => setMdMenu(true)}
                        >
                          <span>Microsoft Dynamics</span>
                          <span>
                            <BiChevronDown />
                          </span>
                        </span>
                        <ul className={`w-full ${mdMenu ? "" : "hidden"}`}>
                          <Link
                            onClick={() => setDealsInMenu(false)}
                            to={{ pathname: "deals-in", hash: "navision" }}
                          >
                            <li className="p-2 pl-4 text-sm flex items-center hover:bg-blue-900  hover:text-white">
                              Dynamics Navision
                            </li>
                          </Link>
                          <Link
                            onClick={() => setDealsInMenu(false)}
                            to={{
                              pathname: "deals-in",
                              hash: "business-central",
                            }}
                          >
                            <li className="p-2 pl-4 text-sm flex items-center hover:bg-blue-900  hover:text-white">
                              Business Central
                            </li>
                          </Link>
                          <Link
                            onClick={() => setDealsInMenu(false)}
                            to={{
                              pathname: "deals-in",
                              hash: "finance-and-operation",
                            }}
                          >
                            <li className="p-2 pl-4 text-sm flex items-center hover:bg-blue-900  hover:text-white">
                              Finance & Operation
                            </li>
                          </Link>
                        </ul>
                      </li>
                    </Link>
                    <li className="relative">
                      <span
                        className="w-full p-2 text-sm flex items-center hover:bg-blue-900  hover:text-white"
                        onMouseEnter={() => setRetailMenu(true)}
                      >
                        <span>Retail</span>
                        <span>
                          <BiChevronDown />
                        </span>
                      </span>
                      <ul
                        className={`w-full bg-white ${
                          retailMenu ? "" : "hidden"
                        }`}
                      >
                        <Link
                          onClick={() => setDealsInMenu(false)}
                          to={{ pathname: "deals-in", hash: "ls-retail" }}
                        >
                          <li className="p-2 pl-4 text-sm hover:bg-blue-900  hover:text-white">
                            LS Retail
                          </li>
                        </Link>
                        <Link
                          onClick={() => setDealsInMenu(false)}
                          to={{ pathname: "deals-in", hash: "ls-central" }}
                        >
                          <li className="p-2 pl-4 text-sm hover:bg-blue-900  hover:text-white">
                            LS Central
                          </li>
                        </Link>
                      </ul>
                    </li>
                    <Link
                      onClick={() => setDealsInMenu(false)}
                      to={{ pathname: "deals-in", hash: "power-bi" }}
                    >
                      <li className="p-2 text-sm hover:bg-blue-900  hover:text-white">
                        Power BI
                      </li>
                    </Link>
                    <Link
                      onClick={() => setDealsInMenu(false)}
                      to={{ pathname: "deals-in", hash: "jet-reports" }}
                    >
                      <li className="p-2 text-sm hover:bg-blue-900  hover:text-white">
                        Jet Reports
                      </li>
                    </Link>
                    <Link
                      onClick={() => setDealsInMenu(false)}
                      to={{ pathname: "deals-in", hash: "dynamic-web" }}
                    >
                      <li className="p-2 text-sm hover:bg-blue-900  hover:text-white">
                        Dynamic Web
                      </li>
                    </Link>
                    <Link
                      onClick={() => setDealsInMenu(false)}
                      to={{ pathname: "deals-in", hash: "oracle" }}
                    >
                      <li className="p-2 text-sm hover:bg-blue-900  hover:text-white">
                        Oracle
                      </li>
                    </Link>
                  </ul>
                </li>
              </span>
              <Link to="/about-us">
                <li className="flex items-center hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                  About Us
                </li>
              </Link>
              <Link to="/industries">
                <li className="hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                  Industries
                </li>
              </Link>
              <Link to="/contact">
                <li className="hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                  Contact
                </li>
              </Link>
              <li className="hover:cursor-pointer hover:border-solid hover:border-b-2 hover:border-black ml-2 mr-2 font-semibold uppercase hover:ease-in duration-100">
                Blog
              </li>
            </ul>
          </div>
          <div className="md:hidden flex self-center text-3xl pr-2 ">
            <CgMenu onClick={() => setToggleSidebar(true)} />
            {toggleSidebar && (
              <Sidebar
                setOpenSidebar={setToggleSidebar}
                openSidebar={toggleSidebar}
              />
            )}
          </div>
        </div>
      </section>
    </nav>
  );
}
