import React, { useEffect } from "react";
import { MdFlatware, MdOutlineAirplanemodeActive } from "react-icons/md";
import { GiChemicalDrop } from "react-icons/gi";
import { FaWarehouse } from "react-icons/fa";
import { TiShoppingCart } from "react-icons/ti";

export default function Industries() {
  const verticals = [
    {
      icon: <MdFlatware />,
      heading: "Food And Beverages",
      content:
        "This solution is intended to help food and beverage manufacturers simplify everything from ensuring all ingredients are probably measured and implemented in each batch to tracing batches and upkeep inventory items efficiently.Food ERP can assist with growing food and beverage complexities and innovation pressures seamlessly with quality assurance, intuitive inventory management, traceability and compliance adherence.",
      path: "",
    },
    {
      icon: <GiChemicalDrop />,
      heading: "Agro And Chemical",
      content:
        "Industries for chemical, pesticide, & fertilizer includes intricate processes and operations. Entire industry has to take safety measures and caution in order to avoid any harm to health as chemicals, pesticides, and fertilizers are heavily toxic in nature. Due to this operations and processes has to be carried out with extreme precision. Chemical compositions of all chemicals, pesticides, & fertilizers has to be accurate so that the product quality does not get ruined.ERP software is the best solution to deal with all the problems that occurs in chemical, pesticides, & fertilizers industry.",
      path: "",
    },
    {
      icon: <FaWarehouse />,
      heading: "Trade,Warehouse & Distribution",
      content:
        "The Trading and Distribution module in ERP enables businesses with complete solutions to manage sales, purchases, logistics, inventory and finance. The module also caters to all operations concerning supply chain and vendor management, enabling users to store and access data with ease and simplicity. It removes the usual complexities surrounding supply chain management and makes it easier for businesses to have a well-balanced inventory.",
      path: "",
    },
    {
      icon: <TiShoppingCart />,
      heading: "Retail & E-Commerce",
      content:
        "Seamlessly connect your storefront and back office, and create lifelong customers with a true omnichannel solution. Automate every facet of your business, but retain the flexibility to run things your way.The global retail market has seen considerable growth in the past few years and is emerging as one of the largest sectors of the economy. Businesses need to implement multi-channel retail as using a single channel for marketing is not sufficient for customers who find it convenient to use tablets and smart phones to make purchases.",
      path: "",
    },
    {
      icon: <MdOutlineAirplanemodeActive />,
      heading: "Aviation",
      content:
        "In aviation, ERP stands for Enterprise Resource Planning. ERP systems organize your internal processes for various parts of your business, like inventory management, supply chain, sales, accounting, and more. ERP systems are essentially a 'system of record' for your business data. Enterprise Resource Planning, in principle, is the integration of real-time analytics and tools to manage various aspects of business operations. It’s supposed to be a “mission control” for business functions including sales, finance, supply chain, and more.",
      path: "",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full h-[260px] md:h-full overflow-hidden bg-gradient-to-r from-gray-800 to-blue-900 flex items-end">
        <span className="w-1/2 p-8 text-xl font-semibold md:text-2xl text-white">
          Industries
        </span>
        <img
          className="w-full translate-y-12 md:w-1/2 md:translate-y-24 p-4"
          src="services-img/skyscraper.png"
          alt="business"
        />
        {/* <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/business-deal.png"
          alt="business-deal"
        />
        <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/business-woman.png"
          alt="business-women"
        /> */}
      </div>
      <div className="max-w-screen-2xl my-10 md:my-16">
        <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8 xl:gap-10 p-4 md:p-6 lg:p-8 xl:p-10">
          {verticals.map((item, key) => (
            <div className="w-full shadow-lg rounded-lg h-80 md:h-[400px] lg:h-[360px]" key={key}>
              <div className="w-full h-[25%] sm:h-[30%] flex relative overflow-hidden rounded-lg mt-4 md:mt-0">
                <div className="w-[25%] sm:w-[30%] bg-blue-900 -translate-x-3 sm:-translate-x-8 lg:-translate-x-6 xl:-translate-x-10 -translate-y-2 rounded-full flex justify-center items-center text-xl sm:text-4xl text-white shadow-xl">
                  {item.icon}
                </div>
                <div className="h-full flex items-center md:text-xl font-semibold text-blue-900 text-md sm:text-lg">
                  {item.heading}
                </div>
              </div>
              <div className="w-full h-[70%] p-4 font-semibold text-xs md:text-sm">
                {item.content}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
