/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { MdEmail, MdLocalPhone } from "react-icons/md";
import emailjs from "@emailjs/browser";
export default function Contact() {
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    message: "",
  });
  const [status, setStatus] = useState("");
  const [fieldStatus, setFieldStatus] = useState("");
  const [isCorrectFields, setIsCorrectFields] = useState(true);
  const serviceId = "service_vubhezv";
  const userId = "fSz9Z3LILHnDsnG4x";
  const templateId = "template_dk16mfe";
  const handleSubmit = (e) => {
    e.preventDefault();
    const phoneNoFormat = /^\d{10}$/;
    const emailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const { firstName, email, phoneNumber } = values;
    if (
      firstName.length > 0 &&
      email.match(emailformat) &&
      phoneNumber.match(phoneNoFormat)
    ) {
      emailjs.send(serviceId, templateId, values, userId).then(
        (response) => {
          console.log("Success!", response);
          setValues({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            company: "",
            message: "",
          });
          setStatus("Success");
        },
        (error) => {
          console.log("Failed", error);
        }
      );
    } else if (firstName.length === 0) {
      setFieldStatus("Please Enter First Name");
      setIsCorrectFields(false);
    } else if (firstName.length > 12) {
      setFieldStatus("First Name length must be less than 12");
      setIsCorrectFields(false);
    } else if (!phoneNumber.match(phoneNoFormat)) {
      setFieldStatus("Invalid Phone Number");
      setIsCorrectFields(false);
    } else if (!email.match(emailformat)) {
      setFieldStatus("Invalid Email");
      setIsCorrectFields(false);
    }
  };
  const handleChange = (e) => {
    setValues((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };
  useEffect(() => {
    if (status === "Success") {
      setTimeout(() => {
        setStatus("");
      }, 2000);
    }
    if (!isCorrectFields) {
      setTimeout(() => {
        setIsCorrectFields(true);
      }, 2000);
    }
  }, [status, isCorrectFields]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const fieldAlert = () => (
    <div className="w-full px-4 py-3 text-center font-semibold text-sm bg-red-100 text-red-500 mb-3 leading-normal ">
      <p>{fieldStatus}</p>
    </div>
  );
  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full h-[260px] md:h-full overflow-hidden bg-gradient-to-r from-gray-800 to-blue-900 flex items-end">
        <span className="w-1/4 p-8 text-xl font-semibold md:text-2xl text-white">
          Contact Us
        </span>
        <img
          className="w-1/4 md:translate-y-12 p-4"
          src="services-img/map-pin.png"
          alt="pin"
        />
        <img
          className="w-1/2 translate-y-6 md:w-1/4 md:translate-y-12 p-4"
          src="services-img/message-sent.png"
          alt="msg-sent"
        />
        <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/call.png"
          alt="call"
        />
      </div>

      <div className="max-w-screen-2xl md:my-16 flex flex-col md:flex-row p-2">
        <div className="w-full p-2 ">
          <div className="text-4xl font-semibold">KPPL Dynamics Pvt. Ltd.</div>
          <div className="w-full flex flex-col mt-8">
            <div className="flex pr-2">
              <div className="flex flex-col">
                <span className="text-blue-900 font-semibold text-xl">
                  Ghaziabad
                </span>
                <span className="text-gray-500 mt-2">
                  10009, Mapple, Paramount Symphony, Crossing Republik,
                  Ghaziabad , UP 201016, India (Registered)
                </span>
              </div>
              <div className="flex flex-col pl-2">
                <span className="text-blue-900 font-semibold text-xl">
                  Noida
                </span>
                <span className="text-gray-500 mt-2">
                  BSI Business Tower, B24, H160, Sector 63 Noida 201301, India (Office)
                </span>
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <span className="flex items-center mt-2">
                <MdEmail className="text-xl text-blue-900" />
                <p className="ml-2 text-gray-500">lokesh@kppl.net</p>
              </span>
              <span className="flex items-center mt-2">
                <MdLocalPhone className="text-xl text-blue-900" />
                <p className="ml-2 text-gray-500">+91 9873048364</p>
              </span>
            </div>
          </div>
        </div>
        <div className="w-full flex justify-around mt-4 md:mt-0 p-2 md:p-4">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14008.068665934241!2d77.4398933!3d28.6292476!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2a27de7be896ae6c!2sKPPL%20Dynamics%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1648552922531!5m2!1sen!2sin"
            className="w-full h-[450px] md:h-full rounded-md shadow-md"
            allowfullscreen=""
            loading="lazy"
          />
        </div>
        <form
          onSubmit={handleSubmit}
          className="w-full flex flex-col rounded-md md:mt-0 mt-4"
        >
          {status && successAlert()}
          {!isCorrectFields && fieldAlert()}
          <span className="pl-2 pr-2 m-2 text-xl font-bold text-blue-900">
            Get In Touch
          </span>
          <span className="pl-2 pr-2 mr-2 ml-2 mb-2 mt-2 text-gray-400 text-sm">
            Please fill the form.
            <br />
            Fields marked * are mandatory.
          </span>
          <div className="w-full flex justify-around">
            <span className="w-full p-2 relative">
              <input
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="text"
                placeholder="First Name*"
                id="firstName"
                name="firstName"
                onChange={handleChange}
                value={values.firstName}
              />
              <label
                htmlFor="firstName"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                First Name*
              </label>
            </span>
            <span className="w-full p-2 relative">
              <input
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="text"
                placeholder="Last Name"
                name="lastName"
                onChange={handleChange}
                value={values.lastName}
              />
              <label
                htmlFor="lastName"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                Last Name
              </label>
            </span>
          </div>
          <div className="w-full flex justify-around">
            <span className="w-full p-2 relative">
              <input
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="tel"
                placeholder="Phone Number*"
                id="Phone-Number"
                name="phoneNumber"
                onChange={handleChange}
                value={values.phoneNumber}
              />
              <label
                htmlFor="Phone-Number"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                Phone Number*
              </label>
            </span>
            <span className="w-full p-2 relative">
              <input
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="email"
                placeholder="Email*"
                id="Email"
                name="email"
                onChange={handleChange}
                value={values.email}
              />
              <label
                htmlFor="Email"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                Email*
              </label>
            </span>
          </div>
          <div className="w-full flex justify-around">
            <span className="w-full p-2 relative">
              <input
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="text"
                placeholder="Company"
                id="Company"
                name="company"
                onChange={handleChange}
                value={values.company}
              />
              <label
                htmlFor="Company"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                Company
              </label>
            </span>
          </div>
          <div className="w-full flex justify-around">
            <span className="w-full p-2 relative">
              <textarea
                className="peer placeholder-transparent w-full text-sm p-2 outline-none border-solid border-b-2 border-gray-100 focus:border-blue-900"
                type="text"
                rows="5"
                placeholder="Message"
                id="Message"
                name="message"
                onChange={handleChange}
                value={values.message}
              />
              <label
                htmlFor="Message"
                className="top-0 left-5 absolute text-blue-900 font-semibold text-sm transition-all peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-4 peer-placeholder-shown:left-4 peer-placeholder-shown:font-normal peer-focus:text-blue-900 peer-focus:top-0 peer-focus:left-5 peer-focus:font-semibold"
              >
                Message
              </label>
            </span>
          </div>
          <div className="w-full p-2 ">
            <button
              type="submit"
              className="w-[80px] p-2 text-sm font-semibold bg-blue-900 rounded-md text-white transition duration-300 hover:bg-blue-800"
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

const successAlert = () => (
  <div className="w-full px-4 py-3 text-center font-semibold text-sm bg-green-100 text-green-500 mb-3 leading-normal ">
    <p>Your Message Sent Successfully !</p>
  </div>
);
