import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function LSRetail() {
  const LSR = {
    logo: "services-img/lsRtl.png",
    heading: "LS Retail",
    image: "services-img/lsRetail.png",
    intro: [
      "LS Retail is an end-to-end retail POS solution for stores or chain of retails, which is powered by Microsoft Dynamics NAV. This integrated solution delivers completed and innovative functionalities to the busiest retailer without the need to build, manage and maintain multiple applications and interfaces.",
      "Its unique use of single application covers your whole retail business from the Point Of Sale (POS) terminals to headquarter. The powerful functions including store management, inventory, merchandising, demand planning and all the back office functions that you would expect to find at head office are available in LS Retail.",
    ],
    why: [
      {
        heading: "Fully Integrated All-in-one Software Solutions",
        content:
          "Manage your business centrally from end to end with our flexible, custom-designed and cloud-enabled software solutions. Cover your whole operations from headquarters, check your performance in real time and react quickly to change. ",
      },
      {
        heading: "Enhance Your Omni-channel Strategy",
        content:
          "ast-changing consumer habits demand innovative business systems. With LS Retail’s cutting-edge omni-channel software solutions you can offer a seamless, personalized consumer experience across all channels and touch-points, including social media. ",
      },
      {
        heading: "Offer Superior Customer Experience",
        content:
          "Give your customers an outstanding shopping experience across all your sales channels and their loyalty and repeat business. Grow your clientele and margins by offering a fast and accurate service in-store, online and on-the-go, including personalized deals and more. ",
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-gray-800 to-blue-900 justify-between items-end">
        <span className="w-full md:w-[35%] p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={LSR.logo} alt="LS Logo" />
        </span>
      </div>
      <div className="w-full md:my-12 my-8 flex flex-col-reverse p-4 md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {LSR.heading} ?
          </h1>
          {LSR.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={LSR.image} alt="hands" />
        </div>
      </div>
      <div className="w-full p-2 md:p-12 flex ">
        <div className="w-full overflow-hidden rounded-lg flex flex-col md:flex-row">
          <img
            className="md:w-[400px] rounded-lg"
            src="services-img/LSRETAILBG.jpg"
            alt=""
          />
          <div className="md:ml-4 flex flex-col justify-around p-6 text-white rounded-lg bg-blue-900">
            <h1 className="w-full text-center md:text-3xl text-2xl font-semibold">
              Why LS Retail Software Solution ?
            </h1>
            {LSR.why.map((item, key) => (
              <div className="flex flex-col p-2 mt-2" key={key}>
                <span className="text-2xl font-semibold">{item.heading}</span>
                <span className="mt-2 text-sm flex">{item.content}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl text-center">
          Request for LS Retail Demo ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2  border-black rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Contact Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
