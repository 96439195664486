import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
export default function BusinessCentral() {
  const bc = {
    logo: "services-img/bcLogo.jpg",
    heading: "Dynamics 365 Business Central",
    image: "services-img/businessC.png",
    intro: [
      "Dynamics 365 Business Central (formerly known as Dynamics NAV and before that, it was Navision!) is a single, end-to-end solution for managing all your business processes, and part of Microsoft's Dynamics 365 range of business applications. It's also incredibly flexible and can be customised to suit any business need.",
      "Business Central is a trusted, proven ERP solution which has served 160,000 customers and is enhanced by Microsoft every year. It has rich functional breadth and can be deployed in the public cloud, private cloud or on-premise.",
      "So, what's the difference between Dynamics NAV & Dynamics 365 Business Central? In some ways the question at hand can be answered in a short sentence; Dynamics 365 Business Central is just the new name for Dynamics NAV.",
    ],
    introFeatures: [
      "With Dynamics 365 Business Central you can revolutionise how you do business, taking streamlining, automation and customer engagement to a new level! It gives you a single, end-to end solution for managing finances, operations, sales and customer service, and an opportunity to easily upgrade from entry-level accounting software and legacy ERP systems.",
      "Dynamics 365 Business Central is great value because it integrates with other Microsoft cloud services including Office 365 and can be customised or extended for specific industry needs with Power Apps, Power Automate and Power BI. So, let’s take a look at what it can do!",
    ],
    features: [
      {
        img: "features-icons/analysis.png",
        heading: "Reporting & Analytics",
        content:
          "Centralise your financial management data across accounting, sales, purchasing, inventory, and customer interactions for an up-to-date, end-to-end view of your business using the various features of Dynamics 365 Business Central. Make faster, more informed decisions when you’re guided by connected processes, predictive analytics, and real-time data.",
      },
      {
        img: "features-icons/processing.png",
        heading: "Order Processing",
        content:
          "Dynamics 365 Business Central enables you to monitor and control your sales-order and purchasing process through automated workflows and dynamically updated inventory levels. You can prevent unnecessary or fraudulent purchases by implementing approvals, plus modify posted invoices in your financial management system and register the correction without hassle.",
      },
      {
        img: "features-icons/sales.png",
        heading: "Sales & Marketing",
        content:
          "With Dynamics 365 Business Central’s sales & marketing features, you can shorten the time from quote to cash by connecting your sales and accounting. Connect the dots between your sales and marketing teams and act quickly on sales-related enquiries right from Outlook. Easily handle multiple ship-to and bill-to addresses per customer, including direct shipment and invoicing addresses.",
      },
      {
        img: "features-icons/warehouse.png",
        heading: "Warehouse Management",
        content:
          "Dynamics 365 Business Central’s Warehouse Management features bring together comprehensive organisational information, automate manual tasks, connect processes and workflows, enable fact-based inventory management, and provide managers with clear visibility into business performance, margins, profitability, and opportunities for improvement.",
      },
      {
        img: "features-icons/project-management.png",
        heading: "Resource & Project Management",
        content:
          "Use Dynamics 365 Business Central to perform common resource & project management tasks and get all the data you need to help manage budgets and monitor progress. Track machine and employee project hours using timesheets, and access real-time data on available resources, such as employees and machinery.",
      },
      {
        img: "features-icons/manufacturing.png",
        heading: "Manufacturing",
        content:
          "If you make it then Dynamics 365 Business Central can help streamline and manage the production process!  Whether you do basic assembly, require full blown MRP or are somewhere in between, we can deliver as much or as little as you need.",
      },
      {
        img: "features-icons/financial.png",
        heading: "Financial Management",
        content:
          "Whether it's contracts, warranties, SLAs or mobile service management, Dynamics 365 Business Central’s features enhance your operational efficiency, provide effective contract management and enforce critical processes - helping to ensure your service team hit SLAs - all of which drive customer loyalty!",
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden bg-gradient-to-r from-gray-800 to-blue-900 flex justify-between items-end">
        <span className="w-full md:w-1/2 p-8 text-xl font-semibold md:text-2xl text-white">
          {bc.heading}
        </span>
        <span className="w-full flex justify-end overflow-hidden">
          <img
            className="w-1/2 md:w-1/4 lg:p-12 lg:translate-y-12"
            src={bc.logo}
            alt="business"
          />
        </span>
      </div>
      <div className="w-full my-10 md:my-16 flex flex-col-reverse p-4 md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is Business Central ?
          </h1>
          {bc.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={bc.image} alt="hands" />
        </div>
      </div>
      <div className="w-full p-4 md:p-10 flex flex-col justify-center items-center bg-blue-900 text-white">
        <h1 className="text-2xl md:text-3xl p-2 border-b-2 font-semibold">
          Features And Functionality
        </h1>
        {bc.introFeatures.map((item, key) => (
          <p key={key} className="w-full text-md text-center font-semibold p-2">
            {item}
          </p>
        ))}
      </div>
      <div className="w-full my-10 md:my-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 md:gap-4 lg:gap-8 xl:gap-10 p-4 md:p-6 lg:p-8 xl:p-10">
        {bc.features.map((item, key) => (
          <div className="w-full shadow-lg rounded-lg h-80" key={key}>
            <div className="w-full h-[25%] sm:h-[30%] flex relative overflow-hidden rounded-lg mt-4 md:mt-0">
              <div className="w-[25%] sm:w-[30%] bg-blue-900 -translate-x-3 sm:-translate-x-8 lg:-translate-x-6 xl:-translate-x-10 -translate-y-2 rounded-full flex justify-center md:pl-4 items-center text-xl sm:text-4xl text-white shadow-xl">
                <img className="w-[35%]" src={item.img} alt="features-img" />
              </div>
              <div className="h-full flex items-center md:text-xl font-semibold text-blue-900 text-md sm:text-lg">
                {item.heading}
              </div>
            </div>
            <div className="w-full h-[60%] flex justify-center items-center p-4 font-semibold text-sm sm:text-base">
              {item.content}
            </div>
          </div>
        ))}
      </div>
      <div className="w-full bg-blue-900 text-white p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl">
          Want to chat about Business Central ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2 rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Connect Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
