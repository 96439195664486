import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function PowerBi() {
  
  const BI = {
    logo: "services-img/power-bi-logo.png",
    heading: "Power BI",
    image: "services-img/power-bi.png",
    intro: [
      "Microsoft Power BI is a product designed to visualise the data inside your business and help you to drive your analysis journey forward, giving better insights to guide your strategic business decision making.",
      "Power BI is predominantly made up of dashboards and reports. Dashboards provide a visual summary of your data and you can drill down on the tiles contained in a dashboard to view a report with deeper analysis on the figures. These can be packed up into an app, which can then be shared amongst your team.",
      "Power BI’s tiles contain data from many sources, such as Excel documents, your ERP system and many more; the tiles can then be customised to represent the data in any way you wish.",
      "Data can be reflected in graphs, charts and figures, and the tiles themselves can be filtered in advanced ways. The information is easy to consume, built for comparisons to be made and insights to be gained, and hovering over any section gives extra detail.",
      "With a simplistic action bar, the clean looking interface makes for a more focused approach, where data is the centre of your experience. The dropdown menus are familiar to any Office 365 user and can be navigated easily without actions taking up unnecessary room on the sidebar.",
    ],
    advantage: [
      "The advantages of a platform like Power BI are vast. With regular functional improvements through updates from Microsoft, the reporting power is firmly in your hands.",
      "When working on a report, it’s quick and easy to check when the data was last updated and who by, giving you the peace of mind that the data is relevant and accurate. You’re also able to add comments to particular tiles, tagging members of your team to action them on what you’ve discovered. Automate your repetitive actions by subscribing to receive emails about particular reports so that emails and mobile notifications are sent to you at a specified time and date. For example, that same Monday morning report but now without the human error and delay.",
      "The alerting functionality is also powerful and vast; you can create alerts on tiles to send you a notification when, for example, the total category volume in a report exceeds 70,000. Why spend your time sat in Power BI when the numbers can be doing the work for you and automation can supply you with what you need?",
      "But the reports don’t have to be contained within Power BI. Export to Excel, PDF and PowerPoint to continue working with that data alongside your big presentation. The exporting feature also includes any filters you have set on that data, so that you’re only seeing the important sections you require. As well as filters, you can use focus mode in Power BI to see more of what you need and less of what you don’t. By drilling down on tiles, you can ‘focus’ on the chart in more detail and then utilise the ‘insights’ functionality where the artificial intelligence tools inside Power BI spring to life. These tools can find similarities and differences in data across any period, as well as noticeable changes that you should be aware of. All these features are available for the mobile, desktop and web versions, meaning all the reporting and AI capabilities can travel with you wherever you go.",
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-yellow-400 to-yellow-600 justify-between items-end">
        <span className="w-3/4 md:w-1/3 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={BI.logo} alt="LS Logo" />
        </span>
      </div>
      <div className="w-full flex flex-col-reverse p-4 items-center md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {BI.heading} ?
          </h1>
          {BI.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
        </div>
        <div className="w-full my-10 md:my-16 md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={BI.image} alt="hands" />
        </div>
      </div>
      <div className="w-full p-2 ">
        <div className="w-full overflow-hidden rounded-lg flex flex-col justify-center md:flex-row">
          <img
            className="rounded-lg"
            src="services-img/PowerBI_Diagram.png"
            alt=""
          />
        </div>
        <div className="flex flex-col my-10 md:my-16 mt-2 justify-around p-6 md:p-10 text-white rounded-lg bg-blue-900">
          <h1 className="w-full text-center md:text-3xl text-2xl font-semibold">
            Data at your fingertips
          </h1>
          {BI.advantage.map((content, key) => (
            <div className="flex flex-col p-2" key={key}>
              <span className="flex">{content}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl text-center">
          Want to chat about Power BI ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2  border-black rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Contact Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
