import React, { useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

export default function DealsIn() {
  const location = useLocation();
  const main = useRef();
  const navRef = useRef();
  const bcRef = useRef();
  const lsrRef = useRef();
  const lscRef = useRef();
  const jetRef = useRef();
  const dwRef = useRef();
  const biRef = useRef();
  const foRef = useRef();
  const oracleRef = useRef();
  const scroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  useEffect(() => {
    switch (location.hash) {
      case "#navision":
        scroll(navRef);
        break;
      case "#business-central":
        scroll(bcRef);
        break;
      case "#ls-retail":
        scroll(lsrRef);
        break;
      case "#ls-central":
        scroll(lscRef);
        break;
      case "#jet-reports":
        scroll(jetRef);
        break;
      case "#power-bi":
        scroll(biRef);
        break;
      case "#finance-and-operation":
        scroll(foRef);
        break;
      case "#oracle":
        scroll(oracleRef);
        break;
      case "#dynamic-web":
        scroll(dwRef);
        break;
      default:
        scroll(main);
    }
  }, [location.hash]);
  const solutions = [
    {
      id: "microsoft_dynamics_navision",
      logo: "services-img/NAV.png",
      image: "services-img/Dynamics-Nav.png",
      path: "/dynamics-navision",
      refer: navRef,
      heading: "Microsoft Dynamics Navision",
      content:
        "Microsoft Dynamics NAV is an enterprise resource planning (ERP) application from Microsoft. A part of the Microsoft Dynamics suite, Microsoft Dynamics NAV is intended to assist businesses with various functions like finance, supply chain management, manufacturing, customer relationship management, analytics and eCommerce for small and medium-sized companies and local subsidiaries of large international groups.",
    },
    {
      id: "business_central",
      logo: "services-img/bc365.png",
      image: "services-img/businessC.png",
      path: "/d365-business-central",
      refer: bcRef,
      heading: "Business Central",
      content:
        "Microsoft Dynamics 365 Business Central is a cloud-based, all-in-one business management solution which enables customers to upgrade from their entry-level accounting software or legacy ERP system to one single, comprehensive solution to manage finances, operations, sales and customer service. Helping organisations streamline their processes, improve customer relations and enable growth.",
    },
    {
      id: "ls_etail",
      logo: "services-img/retail.png",
      image: "services-img/lsRetail.png",
      path: "/ls-retail",
      refer: lsrRef,
      heading: "LS Retail",
      content:
        "LS Retail is an end-to-end retail POS solution for stores or chain of retails, which is powered by Microsoft Dynamics NAV. This integrated solution delivers completed and innovative functionalities to the busiest retailer without the need to build, manage and maintain multiple applications and interfaces. Its unique use of single application covers your whole retail business from the Point Of Sale (POS) terminals to headquarter. The powerful functions including store management, inventory, merchandising, demand planning and all the back office functions that you would expect to find at head office are available in LS Retail.",
    },
    {
      id: "ls_central",
      logo: "services-img/lscentral.png",
      image: "services-img/central.png",
      path: "/ls-central",
      refer: lscRef,
      heading: "LS Central",
      content:
        "LS Central is a Point of Sale (POS) software solution that works seamlessly with Microsoft	Dynamics 365 Business Central.These POS solutions are available for Retail, Restaurants, Pharmacy, Hotels and Forecourt operations.",
    },
    {
      id: "jet_reports",
      logo: "services-img/jetReports.png",
      image: "services-img/JetReports.jpg",
      path: "/jet-reports",
      refer: jetRef,
      heading: "Jet Reports",
      content:
        "Jet Reports is a solution to end those reporting woes, a complete business intelligence weapon for your arsenal. Users can enjoy and thrive in the familiar environment of Excel, with all of the functions and features they've always used in addition to Jet's powerful benefits. It is the perfect tool to use for reporting on data within your ERP solution, as it was built specifically for Dynamics NAV and Business Central integration.",
    },
    {
      id: "dynamic_web",
      logo: "services-img/DW-Logo_OG.png",
      image: "services-img/dynamicweb9.Jpg",
      path: "/dynamic-web",
      refer: dwRef,
      heading: "Dynamic Web",
      content:
        "Dynamicweb brings you a complete all-in-one ecommerce solution that works with your Business Central / NAV system.",
    },
    {
      id: "power_bi",
      logo: "services-img/power-bi-2.png",
      image: "services-img/power-bi.png",
      path: "/power-bi",
      refer: biRef,
      heading: "Power BI",
      content:
        "Power BI is a collection of software services, apps, and connectors that work together to turn your unrelated sources of data into coherent, visually immersive, and interactive insights. Your data may be an Excel spreadsheet, or a collection of cloud-based and on-premises hybrid data warehouses. Power BI lets you easily connect to your data sources, visualize and discover what’s important, and share that with anyone or everyone you want.",
    },
    {
      id: "oracle",
      logo: "services-img/oracle.png",
      image: "services-img/oracle.jpg",
      path: "/oracle",
      refer: oracleRef,
      heading: "Oracle",
      content:
        "Oracle EBS ERP	is your mission critical system which generates and/or records revenue. The last few days of the month end are crucial because this is the period in which most of the revenue is recorded, orders are booked etc. Hence it is essential that system is available, performs well and scales up to the higher needs during this critical period.",
    },
    {
      id: "finance_and_operations",
      logo: "services-img/fandi.png",
      image: "services-img/FandO.png",
      path: "/finance-and-operations",
      refer: foRef,
      heading: "Finance & Operations",
      content:
        "Microsoft Dynamics AX (now Microsoft Dynamics 365) is an ERP system suitable for midsize to large enterprises. The solution has particular strengths in manufacturing and distribution and is also capable of serving buyers across multiple industry verticals. The solution can be installed on-premises or be hosted in the cloud for remote accessibility over the web.",
    },
  ];

  return (
    <section className="w-full  flex flex-col items-center" ref={main}>
      <div className="w-full h-[260px] md:h-full overflow-hidden bg-gradient-to-r from-gray-800 to-blue-900 flex items-end">
        <span className="w-1/4 p-8 text-xl font-semibold md:text-2xl text-white">
          Solutions
        </span>
        <img
          className="w-1/2 translate-y-6 md:w-1/4 md:translate-y-12 p-4"
          src="services-img/entrepreneur.png"
          alt="business"
        />
        <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/business-deal.png"
          alt="business-deal"
        />
        <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/business-woman.png"
          alt="business-women"
        />
      </div>

      <div className="max-w-screen-2xl md:my-16">
        {solutions.map((item, index) => (
          <div
            className={`w-full p-2 items-center md:p-4 flex flex-col-reverse ${
              index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
            }`}
            key={index}
            id={item.id}
            ref={item.refer}
          >
            <div
              className={`w-full shadow-lg p-4 md:p-8 ${
                index % 2 === 0 ? " md:pl-10" : "md:pr-10"
              } flex flex-col rounded-md justify-around items-start`}
            >
              <div className="w-full flex justify-start p-2">
                <img
                  className="p-2 sm:w-1/2 md:w-[20%]"
                  src={item.logo}
                  alt="logo"
                />
              </div>
              <div className="w-full text-center md:text-justify md:w-[85%] p-2">
                <h1 className="text-xl md:text-2xl font-black mb-2 ">
                  {item.heading}
                </h1>
                <p>{item.content}</p>
              </div>
              <div className="p-2 self-center md:self-start">
                <Link to={item.path}>
                  <button className="p-2 pl-3 pr-3 text-xs font-semibold bg-blue-900 rounded-md text-white transition hover:bg-blue-800 hover:shadow-lg duration-300">
                    Details
                  </button>
                </Link>
              </div>
            </div>
            <div className="w-[90%] md:w-1/2 flex justify-end items-center p-4 translate-y-10 md:translate-y-0">
              <img
                className={`p-2 rounded-md border shadow-xl  ${
                  index % 2 === 0
                    ? "mr-2 md:-translate-x-10"
                    : "ml-2 md:translate-x-10"
                }`}
                src={item.image}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
