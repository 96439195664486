import React from "react";
import Bgimg from "../assets/servicesBg.jpg";
import Nav from "../assets/NAV.png";
import BC365 from "../assets/bc365.png";
import JetR from "../assets/jetReports.png";
import Retail from "../assets/retail.png";
import DW from "../assets/DW-Logo_OG.png";
import LSC from "../assets/lscentral.png";
import Oracle from "../assets/oracle.png";
import FandI from "../assets/fandi.png";
import BI from "../assets/power-bi-2.png";
import { Link } from "react-router-dom";
export default function Services() {
  return (
    <div className="w-full relative z-20 overflow-hidden flex flex-col items-center">
      <div className="z-20 flex w-3/4 md:w-1/2 self-center p-2 flex-col">
        <h2 className="text-center p-2 font-semibold text-blue-900 text-4xl md:text-5xl">
          Solutions We Offer
        </h2>
        <p className="mt-4 text-lg text-center font-semibold">
          Find our latest projects we have worked on and get started.
        </p>
      </div>
      <div
        className="absolute hidden md:flex bg-fixed w-full z-10 translate-y-36"
        style={{ height: 900, backgroundImage: `url(${Bgimg})`, opacity: 0.55 }}
      ></div>
      <div className="max-w-screen-2xl bg-gradient-to-r from-gray-800 to-blue-900 md:bg-gradient-to-r md:from-transparent md:to-transparent p-6 md:p-8 z-20 flex flex-col justify-center sm:grid sm:grid-cols-2 sm:gap-4 lg:gap-x-10 md:grid md:grid-cols-3 md:m-20 text-center">
        <div className=" mt-4 md:mt-0 md:p-4 flex flex-col p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={Nav} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Microsoft Dynamics NAV (formerly Navision) is flexible business
              software, designed specifically for medium-sized businesses with
              industry-specific requirements.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "navision" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={BC365} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
            Microsoft Dynamics 365 Business Central is a cloud-based, all-in-one business management solution.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "business-central" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={Retail} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              LS Retail is a complete all-in one software solution that meets
              your needs, whatever your retail industry, size, or target market.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "ls-retail" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={LSC} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
            LS Central is a Point of Sale (POS) software solution that works seamlessly with Microsoft Dynamics 365 Business Central.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "ls-central" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={JetR} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Quickly and easily generate timely, accurate financial reports in
              the format that works for you, right inside of Excel.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "jet-reports" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={DW} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Dynamic web offers a cloud based eCommerce suite.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "#dynamic-web" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={BI} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Build powerful and stimulating visualizations to disseminate
              across your business.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "power-bi" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={Oracle} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Oracle Cloud solutions redefine how businesses can modernize,
              innovate, and compete in a digital world.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "oracle" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-4 md:mt-0 md:p-4 flex flex-col h-full p-2 rounded-md shadow-md bg-white hover:ease-in-out duration-500 hover:shadow-2xl hover:shadow-blue-100">
          <div className="h-1/4 flex justify-center items-center">
            <span className="text-white overflow-hidden p-2  flex justify-center items-center rounded-full">
              <img className="h-10" src={FandI} alt="nav" />
            </span>
          </div>
          <div className="h-1/2 flex justify-center items-center mt-2">
            <p className="p-2 text-gray text-sm font-semibold">
              Dynamics 365 Finance & Operations is a cloud-based Enterprise
              Resource Planning (ERP) system well-suited for large or
              diversified companies.
            </p>
          </div>
          <div className="flex justify-center items-center h-1/4">
            <Link
              className="w-full"
              to={{ pathname: "deals-in", hash: "finance-and-operation" }}
            >
              <button className="text-xs font-semibold rounded mt-2 w-1/4 hover:ease-in-out duration-500 self-center hover:bg-blue-800 bg-blue-900 text-white p-2">
                More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
