import React, { useEffect } from "react";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function JetReports() {
  const JR = {
    logo: "services-img/jet-reports-logo.png",
    heading: "Jet Reports",
    image: "services-img/JetReports.jpg",
    intro: [
      "Advanced financial and business reporting inside of Excel and on the web.",
      "Jet Reports (previously Jet Professional) is the original Microsoft Dynamics Excel add-in which allows Dynamics 365 Business Central users to pull together fast, financial and business reporting inside of Microsoft Excel from all live Microsoft Dynamics operational data, in the format that works for you.",
      "Any report – exactly the way you want it, no matter where you are. From advanced business reports to sophisticated financial statements, there is no exporting, no copying and pasting and no programming ever.",
    ],
    benifits: [
      {
        heading: "Eliminate errors & improve accuracy",
        content:
          "Access & pull all your data directly in Excel instead of copying, pasting & manually exporting.",
      },
      {
        heading: "Cut reporting time and cost",
        content: "No more hard-coding or programming reports required.",
      },
      {
        heading: "Have the answers you need",
        content:
          "Drill-down on the numbers directly from Excel and access them from anywhere.No more hard-coding or programming reports required.",
      },
    ],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-yellow-400 to-yellow-600 justify-between items-end">
        <span className="w-3/4 md:w-1/3 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={JR.logo} alt="JR Logo" />
        </span>
      </div>
      <div className="w-full flex flex-col-reverse p-4 items-center md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {JR.heading} ?
          </h1>
          {JR.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={JR.image} alt="hands" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-around p-6 text-white bg-blue-900">
        <h1 className="w-full text-center md:text-3xl text-2xl font-semibold">
          Benifits of Jet Reports
        </h1>
        <div className="w-full p-2 md:p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          {JR.benifits.map((item, key) => (
            <div
              className="w-full bg-white text-black rounded-md shadow-xl"
              key={key}
            >
              <h1 className="p-4 border-b-2 border-blue-900 text-center text-blue-900 text-xl font-semibold">
                {item.heading}
              </h1>
              <p className="p-4 text-center text-sm font-semibold">
                {item.content}
              </p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl text-center">
          Request for Jet Reports Demo ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2  border-black rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Contact Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
