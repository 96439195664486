import React from "react";

export default function Services() {
  const servicesData = [
    {
      key: "1",
      img: "icons/bs-report.png",
      title: "Business Intelligence Report",
    },
    {
      key: "2",
      img: "icons/software.png",
      title: "Software Development .NET",
    },
    { key: "3", img: "icons/service.png", title: "Support Service" },
    {
      key: "4",
      img: "icons/implementation.png",
      title: "ERP & CRM Implementation",
    },
    {
      key: "5",
      img: "icons/consulting.png",
      title: "Business Process Consulting",
    },
    {
      key: "6",
      img: "icons/system.png",
      title: "System Audit",
    },
    {
      key: "7",
      img: "icons/cloud.png",
      title: "Migration to Cloud",
    },
    {
      key: "8",
      img: "icons/upgrade.png",
      title: "System Upgrade",
    },
    {
      key: "9",
      img: "icons/iphone.png",
      title: "Mobile Apps Development",
    },
  ];
  return (
    <section className="w-full my-12 md:my-20 relative flex justify-center">
      <div className="max-w-screen-2xl flex p-2 md:flex-row flex-col">
        <div
          className="bg-cover rounded-md w-full md:w-1/2  flex justify-center items-center text-white"
          style={{
            backgroundImage: "url(services-img/solution_engineering.jpg)",
          }}
        >
          <div className="p-2 w-full h-full flex flex-col justify-center items-center">
            <h1 className="text-3xl font-semibold p-2">Our Services</h1>
            <p className="text-center w-3/4 p-2 font-semibold">
              KPPL Dynamics is a 360 Degree Microsoft Dynamics 365 Solution
              provider. Our service offerings include, but are no restricted to:
            </p>
          </div>
        </div>
        <div className="w-full md:w-3/4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 p-8">
          {servicesData.map((item) => (
            <div
              className="w-full hover:bg-blue-900 hover:text-white h-28 overflow-hidden flex justify-center items-center rounded-md shadow-xl border-r-4 border-blue-900 transition-all duration-300"
              key={item.key}
            >
              {/* <div className="w-1/4 relative flex justify-center items-end h-full bg-blue-900">
              </div> */}
              <div className="w-full flex flex-col justify-center items-center">
                <img
                  className="flex bg-blue-900 rounded-full w-[60px] p-2 shadow-2xl"
                  src={item.img}
                  alt="service-img"
                />
                <p className="p-2 text-sm font-semibold">{item.title}</p>
              </div>
              {/* <div className="w-full flex flex-col self-end text-sm font-semibold">
                <p className="p-2 lg:pb-4">{item.title}</p>
              </div> */}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
