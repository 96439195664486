import React, { useEffect } from "react";
import { TiChevronRight } from "react-icons/ti";
import HandS from "../assets/hand-s.jpeg";

export default function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full">
      <div className="w-full h-[260px] md:h-full overflow-hidden bg-gradient-to-r from-gray-800 to-blue-900 flex items-end">
        <span className="w-1/4 p-8 text-xl font-semibold md:text-2xl text-white">
          About Us
        </span>
        <img
          className=" w-1/2 md:w-1/4 p-4"
          src="services-img/about-us.png"
          alt="pin"
        />
        <img
          className="w-1/2 translate-y-6 md:w-1/4 md:translate-y-12 p-4"
          src="services-img/remote-team.png"
          alt="msg-sent"
        />
        <img
          className="hidden md:block md:w-1/4 md:translate-y-12 p-4"
          src="services-img/server.png"
          alt="call"
        />
      </div>
      <div className="w-full flex flex-col-reverse p-4 md:p-12 md:flex-row">
        <div className="w-full md:w-3/4 p-2 flex flex-col">
          <p className="w-full md:w-3/4 text-lg font-semibold md:pr-4">
            We started our operations in the year 2019 ,a full-scale IT &
            Software development company with its delivery presence in
            Ghaziabad, India. Our core objective is to deliver innovative and
            personalized services to organizations of any scale to keep up with
            rapidly evolving the digital business environment with convenience,
            peace of mind and the supreme return on investment. We, at KPPL
            Dynamics, take pride in providing services that create value for our
            client’s business and help them achieve your business.
          </p>
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            Who We Are ?
          </h1>
          <p className="w-full md:w-3/4 mt-4 text-lg font-semibold">
            KPPL is a preferred business application & IT solution partner for
            global businesses. We focus on delivering value to the organization
            through business application, cloud & analytics. We are working
            extensively in implementing Microsoft Dynamics business
            applications, LS Retail, Power BI, Jet Reports & E-Commerce
            Solution.
          </p>
        </div>
        <div className="w-full md:w-1/4 rounded-xl overflow-hidden shadow-md">
          <img className="w-full h-full" src={HandS} alt="hands" />
        </div>
      </div>
      <div className="w-full p-2 md:p-12 flex ">
        <div className="w-full overflow-hidden rounded-lg flex flex-col md:flex-row">
          <img
            className="md:w-[300px] rounded-lg"
            src="services-img/goal.jpg"
            alt=""
          />
          <div className="mt-2 md:mt-0 md:ml-4 flex flex-col justify-around p-6 text-white rounded-lg bg-blue-900">
            <div className="flex flex-col">
              <span className="text-2xl font-semibold">Vision</span>
              <span className="mt-2 text-sm flex">
                <span className="mr-2 flex text-lg">
                  <TiChevronRight />
                </span>
                The quality assurance possesses rich experience in the rice mill
                machinery manufacturing & has a thorough knowledge of machinery
                application.
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-semibold">Mission</span>
              <span className="mt-2 text-sm flex">
                <span className="mr-2 flex text-lg">
                  <TiChevronRight />
                </span>
                Our mission is focused on customer success, by implementing
                business models to enhance the competitiveness of our customers
                and by providing tools to eliminate inefficiencies, to improve
                overall business performance and to deal with market changes in
                a responsive way.
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-2xl font-semibold">Result</span>
              <span className="mt-2 text-sm flex">
                <span className="mr-2 flex text-lg">
                  <TiChevronRight />
                </span>
                Proficiency, professionalism, methodology, technology : the
                synergy between KPPL Dynamics pillars allows us to offer value
                added solutions.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full md:my-20 flex justify-center">
        <div className="p-2 md:p-0 w-full md:w-3/4 flex flex-col">
          <div className="w-full flex justify-center">
            <span className="text-2xl md:text-3xl font-semibold">
              Key Differentiators
            </span>
          </div>
          <div className="flex mt-6 md:mt-10 w-full justify-around flex-col md:flex-row">
            <div className="p-4 mt-2 md:mt-0 md:m-2 flex w-full md:w-1/3 bg-blue-700 rounded-lg shadow-xl text-sm md:text-base ">
              <div className="w-1/4 flex justify-center items-center md:border-r-2 md:border-dotted md:border-white">
                <span className="w-12 h-12 md:h-16 md:w-16 flex justify-center items-center rounded-full bg-white shadow-2xl font-semibold">
                  01
                </span>
              </div>
              <div className="w-3/4 text-white pl-2">
                <div className="text-lg mt-2 font-semibold uppercase">
                  Expertise
                </div>
                <div className="font-semibold text-sm mb-2">
                  Vertical-Centric Centre of Excellence
                </div>
              </div>
            </div>
            <div className="p-4 mt-2 md:mt-0 md:m-2 flex w-full md:w-1/3 bg-orange-500 rounded-lg shadow-xl text-sm md:text-base">
              <div className="w-1/4 flex justify-center items-center md:border-r-2 md:border-dotted md:border-white">
                <span className="w-12 h-12 md:h-16 md:w-16 flex justify-center items-center rounded-full bg-white shadow-2xl font-semibold">
                  02
                </span>
              </div>
              <div className="w-3/4 text-white pl-2">
                <div className="text-lg mt-2 font-semibold uppercase">
                  Experience
                </div>
                <div className="font-semibold text-sm mb-2">
                  Strategic Assessment of Business Pain Areas
                </div>
              </div>
            </div>
            <div className="p-4 mt-2 md:mt-0 md:m-2 flex w-full md:w-1/3 bg-green-700 rounded-lg shadow-xl text-sm md:text-base ">
              <div className="w-1/4 flex justify-center items-center md:border-r-2 md:border-dotted md:border-white">
                <span className="w-12 h-12 md:h-16 md:w-16 flex justify-center items-center rounded-full bg-white shadow-2xl font-semibold">
                  03
                </span>
              </div>
              <div className="w-3/4 text-white pl-2">
                <div className="text-md md:text-lg mt-2 font-semibold uppercase">
                  Excellence
                </div>
                <div className="font-semibold text-sm mb-2">
                  Unparalleled Microsoft Dynamics Technology Expertise
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="w-full bg-gray-100 flex justify-center">
        <div className="w-3/4 mt-10 mb-10 flex flex-col">
          <div className="w-full flex justify-center">
            <span className="text-3xl font-semibold">Team Members</span>
          </div>
          <div className="w-full mt-10 flex flex-col md:flex-row items-center">
            <div className="w-1/4">
              <div className="flex flex-col justify-center items-center p-4">
                <span className="flex justify-center items-center w-40 h-40 rounded-full shadow-md">
                  Img
                </span>
                <p className="mt-2 text-center font-semibold">Name</p>
                <p className="text-center font-semibold text-gray-700 text-xs">
                  Post
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
