import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import { BiMessageSquareDetail } from "react-icons/bi";
//import { IoSend } from "react-icons/io5";
import { Link } from "react-router-dom"

export default function ContactBox() {
  //const [openContact, isOpenContact] = useState(false);

  return (
    <div className="max-w-screen-2xl">
      <div className="p-2 shadow-2xl z-40 fixed top-[85%] sm:top-[78%] left-[80%] md:top-[88%] md:left-[85%] flex items-center font-semibold bg-gray-100 hover:bg-green-700 rounded-md hover:text-white hover:ease-in-out duration-500">
        <a className="flex" target="_blank" rel="noreferrer" href="https://wa.me/+919873048364">
          <span className="p-2 text-lg">
            <BsWhatsapp />
          </span>
          <span className="p-2 hidden xl:block ">Whatsapp</span>
        </a>
      </div>
      {/* Contact Us */}
      {/* {openContact && (
        <div className="overflow-hidden rounded-md w-[294px] shadow-2xl z-50 fixed top-[40%] left-[25%] md:top-[38%] md:left-[78%]">
          <div className="w-full flex justify-center items-center h-[40px] text-center text-white bg-blue-900">
            <span className="font-semibold ">Contact Us</span>
          </div>
          <div className="w-full p-2 bg-white border border-b-2 border-gray-100">
            <input
              className="outline-none"
              type="text"
              name="user_name"
              placeholder="Name*"
              required
            />
          </div>
          <div className="w-full p-2 bg-white border border-b-2 border-gray-100">
            <input
              className="outline-none"
              type="email"
              name="user_email"
              id=""
              placeholder="Email*"
              required
            />
          </div>
          <div className="w-full p-2 bg-white border border-b-2 border-gray-100 h-full">
            <textarea
              className="outline-none"
              rows="6"
              cols="35"
              type="text"
              name="message"
              id=""
              placeholder="Message*"
              required
            />
          </div>
          <button
            type="submit"
            value="Send"
            onClick={() => isOpenContact(false)}
            className="p-3 hover:bg-blue-800 bg-blue-900 w-full flex text-white justify-center items-center font-semibold hover:ease-in-out duration-500"
          >
            <span className="mr-2">Send</span>
            <IoSend className="text-xs" />
          </button>
        </div>
      )} */}
      {/* <Link to="/contact">
        <div
          className={`p-2 shadow-2xl z-40 fixed left-[80%] top-[85%] sm:top-[88%] md:left-[88%] flex items-center font-semibold hover:bg-blue-800 rounded-md hover:text-white hover:ease-in-out duration-500 bg-gray-100`}
        >
          <span className="p-2 text-lg">
            <BiMessageSquareDetail />
          </span>
          <span className="p-2 hidden xl:block ">Contact Us</span>
        </div>
      </Link> */}
    </div>
  );
}
