import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { BsArrowRight } from "react-icons/bs";
import { VscArrowSmallRight } from "react-icons/vsc";

export default function Oracle() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const OC = {
    logo: "services-img/Oracle-logo.png",
    heading: "Oracle ERP Cloud",
    image: "services-img/oracle.jpg",
    intro: [
      "Oracle Enterprise Resource Planning, or ERP, Cloud is a Software-as-a-Service (SaaS) platform known for its ability to scale with your business as it grows and expands. Since the software lives and operates in the cloud, state-of-the-art data security is a top priority. Its vertically integrated stack keeps data under control, while its deployment options are flexible and create a multilayer approach.",
      "Interconnectivity is a hallmark of Oracle ERP Cloud’s integrated suite of applications. Data is shared easily and securely between users, allowing for increased production and business process streamlining. As we mentioned, security is a priority; however, it’s one of many components included that are important to end users, such as analytics, automation tools, reporting, user experience and more.",
    ],
    applications: [
      "Enterprise Resource Planning",
      "Supply Chain Management",
      "Human Capital Management",
      "Advertising and Customer Experience",
      "Industry Applications",
      "ISV And Custom Applications",
    ],
    benifits: [
      "Modern and intuitive user interface",
      "Powerful reporting and dashboard capabilities",
      "Rich out-of-the-box functionality",
      "Broad coverage for Finance, Procurement, Projects, SCM",
      "Rapid implementation possible",
      "Highly secured and scalable",
      "Always up-to-date",
      "Lower Total Cost of Ownership",
    ],
  };
  return (
    <div className="w-full">
      <div className="w-full h-[260px] xl:h-[400px] overflow-hidden flex bg-gradient-to-r from-red-600 to-red-800 justify-between items-end">
        <span className="w-3/4 md:w-1/3 p-8 text-xl font-semibold md:text-2xl text-white">
          <img className="w-full " src={OC.logo} alt="JR Logo" />
        </span>
      </div>
      <div className="w-full my-10 md:my-16 flex flex-col-reverse p-4 items-center md:p-10 md:flex-row">
        <div className="w-full md:w-3/4 flex flex-col">
          <h1 className="mt-4 text-3xl text-blue-900 font-semibold">
            What is {OC.heading} ?
          </h1>
          {OC.intro.map((item, key) => (
            <p
              key={key}
              className="w-full text-md font-semibold mt-2 md:mt-4 md:pr-4"
            >
              {item}
            </p>
          ))}
        </div>
        <div className="w-full md:w-1/2 md:h-1/2 flex justify-center items-center overflow-hidden">
          <img className="w-full h-full" src={OC.image} alt="hands" />
        </div>
      </div>
      <div className="flex flex-col items-center justify-around p-6 md:p-10 text-white bg-blue-900">
        <h1 className="w-full text-center md:text-3xl text-2xl font-semibold">
          Oracle Cloud Applications
        </h1>
        <div className="w-full p-2 md:p-4 grid grid-cols-1 md:grid-cols-3 gap-4">
          {OC.applications.map((item, key) => (
            <div
              className="w-full bg-white text-black rounded-md shadow-xl"
              key={key}
            >
              <h1 className="p-4 text-center text-blue-900 text-xl font-semibold">
                {item}
              </h1>
            </div>
          ))}
          <div className="w-full flex flex-col md:flex-row text-sm font-semibold">
            <h1 className="pr-2">Do you want to know more about Oracle ? </h1>
            <a
              href="https://www.oracle.com/in/index.html"
              rel="noreferrer"
              target="_blank"
            >
              <span className="border-b-2">Click Here...</span>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full my-10 md:my-16 flex flex-col md:flex-row p-6">
        <div>
          <img src="services-img/oracle-screen.png" alt="oracle" />
        </div>
        <div className="w-full flex flex-col items-center">
          <div>
            <h1 className="text-blue-900 font-semibold text-xl">
              Benifits of Oracle Cloud Solution
            </h1>
            {OC.benifits.map((item, key) => (
              <p
                className="flex items-center p-2 font-semibold text-sm"
                key={key}
              >
                <span className=" text-blue-900">
                  <VscArrowSmallRight />
                </span>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full bg-blue-900 text-white p-6 md:p-10 flex flex-col sm:flex-row justify-around ">
        <h1 className="text-2xl font-semibold sm:text-3xl">
          Want to chat about Oracle Cloud Solution ?
        </h1>
        <Link to="/contact" className="flex justify-center mt-4 sm:mt-0">
          <button className="flex border-2 rounded-md p-2 font-semibold tranisition hover:ease-in-out duration-300 hover:shadow-2xl md:hover:-translate-y-2">
            Connect Us
            <span className="ml-2 self-center">
              <BsArrowRight />
            </span>
          </button>
        </Link>
      </div>
    </div>
  );
}
